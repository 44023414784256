import React from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";
import LoadingPage from "pages/LoadingPage";

const AUTH_MECHANISM = process.env.REACT_APP_AUTH_MECHANISM;

let tokens;

const handleTokens = (freshTokens) => {
    tokens = freshTokens;
};

const authWrapperMap = {
    OPENID: ({ children }) => (
        <ReactKeycloakProvider
            authClient={keycloak}
            onTokens={handleTokens}
            LoadingComponent={<LoadingPage />}
            initOptions={{ onLoad: "login-required" }}
        >
            {children}
        </ReactKeycloakProvider>
    ),
    CERNSSO: ({ children }) => children,
    LOCAL: ({ children }) => children,
    UNKOWN: () => <div>Please configure an Authentication Mechanism</div>,
};

const logoutHandler = () => {
    switch (AUTH_MECHANISM) {
        case "OPENID":
            keycloak.logout();
            break;
        case "CERNSSO":
            window.location.href =
                "https://espace.cern.ch/authentication/_layouts/15/SignOut.aspx";
            break;
        case "LOCAL":
            alert(`LOCAL development: logout just shows this alert.`);
            break;
        default:
            break;
    }
};

const AuthWrapper = (props) => {
    const render = authWrapperMap[AUTH_MECHANISM] ?? authWrapperMap["UNKOWN"];
    return render(props);
};

export default AuthWrapper;

export { logoutHandler, tokens, AUTH_MECHANISM };
